.card {
	background-color: rgb(216, 197, 175);
	box-shadow: 0px 0px 10px rgb(66, 66, 66);
	max-width: 550px;
	width: 100%;
	border-radius: 3px;
	padding: 1rem;
	&__title {
		text-align: center;
		font-weight: 100;
		font-size: 1.5rem;
	}
}

.stamps {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	justify-content: space-between;
	&__item {
		width: 100px;
		height: 100px;
		margin: 0.3rem;
		pointer-events: none;
	}
	&--allow-edit &__item {
		pointer-events: all;
	}
}
